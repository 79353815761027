import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Banner from './components/BAnner';
import About from './components/About';
import Service1 from './components/Service1';
import Service2 from './components/Service2';
import PreBusiness from './components/PreBusiness';
import Project from './components/Project';
import Sponsors from './components/Sponsors';
import RecentProject from './components/RecentProject';
import Technology from './components/Technology';
import Team from './components/Team';
import Blog from './components/Blog';
import Footer from './components/Footer';
import ProjectDetails from './components/ProjectDetails';
import ServiceDetails from './components/ServiceDetails';
import Contact from './components/Contact';

function App() {
  const [showSlider, setShowSlider] = useState(false);
  const toggleSlider = () => {
    setShowSlider(!showSlider);
  };

  return (  
    <BrowserRouter>
      <>
        <Header toggleSlider={toggleSlider} />
        <Routes>
          <Route path="/" element={
            <>
              <Banner />
              <About />
              <Service1 />
              <Service2 />
              <PreBusiness />
              <Project />
              <Sponsors />
              <RecentProject />
              <Technology />
              <Team />
              <Blog />
             
            </>
          }/>
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service1 />} />
          <Route path="/serviceDetails" element={<ServiceDetails />} />
          <Route path="/project" element={<Project />} />
          <Route path="/projectDetails" element={<ProjectDetails />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
        <Sidebar show={showSlider} toggleSlider={toggleSlider} />
      </>
    </BrowserRouter>
  );
}
export default App;
