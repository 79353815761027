import React from 'react';
import pattern_16 from '../assets/images/background/pattern-16.png';

const Contact = ({ }) => {
    return (
      <>
    <section class="page-title">
		<div class="pattern-layer-one" style={{ backgroundImage: `url(${pattern_16})` }}></div>
    	<div class="auto-container">
			<h2>Contact us</h2>
			<ul class="page-breadcrumb">
				<li><a href="index.html">home</a></li>
				<li>Contact us</li>
			</ul>
        </div>
    </section>
  
	<section class="contact-info-section">
		<div class="auto-container">
		
			<div class="title-box">
				<div class="title">GET IN TOUCH</div>
				<h2>A Monthly Project Fee <br/> Price Plans</h2>
				<div class="text">For genereal enquires you can touch with our front desk supporting team <br/> at <a href="mailto:info@example.com">info@example.com</a> or call on <a href="tel:+8.555.55.55">+8.555.55.55</a></div>
			</div>
			<div class="row clearfix">
				<div class="info-column col-lg-4 col-md-6 col-sm-12">
					<div class="inner-column">
						<div class="content">
							<div class="icon-box"><span class="flaticon-pin"></span></div>
							<ul>
								<li><strong>Address</strong></li>
								<li>125, Suitland Street, USA</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="info-column col-lg-4 col-md-6 col-sm-12">
					<div class="inner-column">
						<div class="content">
							<div class="icon-box"><span class="flaticon-phone-call"></span></div>
							<ul>
								<li><strong>Phone</strong></li>
								<li>+ 786 875 864 75</li>
							</ul>
						</div>
					</div>
				</div>			
				<div class="info-column col-lg-4 col-md-6 col-sm-12">
					<div class="inner-column">
						<div class="content">
							<div class="icon-box"><span class="flaticon-email-1"></span></div>
							<ul>
								<li><strong>E-Mail</strong></li>
								<li>support@globex.com</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="lower-text">We are at 36 places over the country, <a href="#">see our branches.</a></div>
		</div>
	</section>
	<section class="contact-map-section">
		<div class="auto-container">
			<div class="map-boxed">	
				<div class="map-outer">
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d805184.6331292129!2d144.49266890254142!3d-37.97123689954809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad646b5d2ba4df7%3A0x4045675218ccd90!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2s!4v1574408946759!5m2!1sen!2s" allowfullscreen=""></iframe>
				</div>
			</div>
		</div>
	</section>
	<section class="contact-map-section">
		<div class="auto-container">
			<div class="sec-title">
				<div class="clearfix">
					<div class="pull-left">
						<div class="title">SEND YOUR MESSAGE</div>
						<h2>Send Your Message</h2>
					</div>
					<div class="pull-right">
						<div class="text">Our goal is to help our companies maintain or achieve best- in-class <br/> positions in their respective industries and our team works.</div>
					</div>
				</div>
			</div>
			<div class="contact-form">
				<form method="post" action="sendemail.php" id="contact-form">
					<div class="row clearfix">
						<div class="form-group col-lg-6 col-md-6 col-sm-12">
							<label>Your name *</label>
							<input type="text" name="username" placeholder="" required/>
						</div>		
						<div class="form-group col-lg-6 col-md-6 col-sm-12">
							<label>Email address *</label>
							<input type="text" name="email" placeholder="" required/>
						</div>
						<div class="form-group col-lg-6 col-md-6 col-sm-12">
							<label>Phone number *</label>
							<input type="text" name="phone" placeholder="" required/>
						</div>		
						<div class="form-group col-lg-6 col-md-6 col-sm-12">
							<label>Website</label>
							<input type="text" name="subject" placeholder="" required/>
						</div>			
						<div class="form-group col-lg-12 col-md-12 col-sm-12">
							<label>Your Message *</label>
							<textarea name="message" placeholder=""></textarea>
						</div>					
						<div class="form-group text-center col-lg-12 col-md-12 col-sm-12">
							<button class="theme-btn btn-style-three" type="submit" name="submit-form"><span class="txt">Send Now</span></button>
						</div>				
					</div>
				</form>
			</div>	
		</div>
	</section>
      </>
);
};
export default Contact;