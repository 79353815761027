import React from 'react';
import pattern2 from '../assets/images/background/pattern-2.png'; 
import pattern_16 from '../assets/images/background/pattern-16.png';
import news_5 from '../assets/images/resource/news-5.jpg'; 
import news_6 from '../assets/images/resource/news-6.jpg'; 
import news_7 from '../assets/images/resource/news-7.jpg'; 
import news_11 from '../assets/images/resource/news-11.jpg'; 
import news_12 from '../assets/images/resource/news-12.jpg'; 
import news_13 from '../assets/images/resource/news-13.jpg';
import feature_1 from '../assets/images/resource/feature-1.jpg';
import feature_2 from '../assets/images/resource/feature-2.jpg';

const Service1 = ({ }) => {
    return (
      <div>
    <section class="page-title">
		<div class="pattern-layer-one" style={{ backgroundImage: `url(${pattern_16})` }}></div>
    	<div class="auto-container">
			<h2>Services</h2>
			<ul class="page-breadcrumb">
				<li><a href="index.html">home</a></li>
				<li>Services</li>
			</ul>
        </div>
    </section>
   
	<section class="services-page-section">
		<div class="auto-container">
			<div class="row clearfix">
				
			
				<div class="news-block-three col-lg-4 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<a href="services-detail.html"><img src={news_5} alt="" /></a>
						</div>
						<div class="lower-content">
							<div class="content">
								<div class="icon-box">
									<span class="icon flaticon-coding-1"></span>
								</div>
								<h4><a href="services-detail.html">Web Development</a></h4>
								<div class="text">We carry more than just good coding skills. Our experience makes us stand out from other web development.</div>
								<a class="read-more" href="services-detail.html">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>
							</div>
						</div>
					</div>
				</div>
	
				<div class="news-block-three style-two col-lg-4 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="lower-content">
							<div class="content">
								<div class="icon-box">
									<span class="icon flaticon-mobile-app"></span>
								</div>
								<h4><a href="services-detail.html">Web Development</a></h4>
								<div class="text">We carry more than just good coding skills. Our experience makes us stand out from other web development.</div>
								<a class="read-more" href="services-detail.html">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>
							</div>
						</div>
						<div class="image">
							<a href="services-detail.html"><img src={news_6} alt="" /></a>
						</div>
					</div>
				</div>
				
				
				<div class="news-block-three col-lg-4 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<a href="services-detail.html"><img src={news_7} alt="" /></a>
						</div>
						<div class="lower-content">
							<div class="content">
								<div class="icon-box">
									<span class="icon flaticon-computer"></span>
								</div>
								<h4><a href="services-detail.html">UI/UX Design</a></h4>
								<div class="text">Build the product you need on time with an experienced team that uses a clear and effective design process.</div>
								<a class="read-more" href="services-detail.html">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>
							</div>
						</div>
					</div>
				</div>
				
				
				<div class="news-block-three col-lg-4 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<a href="services-detail.html"><img src={news_11} alt="" /></a>
						</div>
						<div class="lower-content">
							<div class="content">
								<div class="icon-box">
									<span class="icon flaticon-web"></span>
								</div>
								<h4><a href="services-detail.html">QA & Testing</a></h4>
								<div class="text">Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software.</div>
								<a class="read-more" href="services-detail.html">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>
							</div>
						</div>
					</div>
				</div>
				
				
				<div class="news-block-three col-lg-4 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<a href="services-detail.html"><img src={news_12} alt="" /></a>
						</div>
						<div class="lower-content">
							<div class="content">
								<div class="icon-box">
									<span class="icon flaticon-monitor-2"></span>
								</div>
								<h4><a href="services-detail.html">IT Consultancy</a></h4>
								<div class="text">Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software.</div>
								<a class="read-more" href="services-detail.html">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>
							</div>
						</div>
					</div>
				</div>
				
		
				<div class="news-block-three col-lg-4 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<a href="services-detail.html"><img src={news_13} alt="" /></a>
						</div>
						<div class="lower-content">
							<div class="content">
								<div class="icon-box">
									<span class="icon flaticon-human-resources"></span>
								</div>
								<h4><a href="services-detail.html">Dedicated Team</a></h4>
								<div class="text">Over the past decade, our customers succeeded by leveraging Intellectsoft’s process of building, motivating.</div>
								<a class="read-more" href="services-detail.html">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</section>

	<section class="featured-section">
		<div class="auto-container">
			<div class="row clearfix">
				<div class="feature-block-two col-lg-6 col-md-12 col-sm-12">
					<div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms" style={{ backgroundImage: `url(${feature_1})` }}>
						<div class="number">35 +</div>
						<h4>Worldwide Work Pair</h4>
						<div class="text">To succeed, every software solution must be deeply integrated into the existing tech environment..</div>
					</div>
				</div>	
			
				<div class="feature-block-two col-lg-6 col-md-12 col-sm-12">
					<div class="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms" style={{ backgroundImage: `url(${feature_2})` }}>
						<div class="number">40 k</div>
						<h4>Happy Clients</h4>
						<div class="text">To succeed, every software solution must be deeply integrated into the existing tech environment..</div>
					</div>
				</div>
			</div>
		</div>
	</section>
<section class="services-section margin-top">
		<div className="pattern-layer" style={{ backgroundImage: `url(${pattern2})` }}>
		</div>
		<div class="auto-container">
			{/* <!-- Sec Title --> */}
			<div class="sec-title light centered">
				<div class="title">WHO WE ARE</div>
				<h2>We deal with the aspects of <br/> professional IT Services</h2>
			</div>
			<div class="row clearfix">
				
				{/* <!-- Service Block --> */}
				<div class="service-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="icon-box">
							<span class="icon flaticon-responsive"></span>
						</div>
						<h5><a href="services-detail.html">IT Soluations</a></h5>
						<div class="text">Sed ut perspiciatis unde omnis iste natus error volup validate your ideas.</div>
						<a href="services-detail.html" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>
					</div>
				</div>
				
				{/* <!-- Service Block --> */}
				<div class="service-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="icon-box">
							<span class="icon flaticon-monitor"></span>
						</div>
						<h5><a href="services-detail.html">Security System</a></h5>
						<div class="text">Sed ut perspiciatis unde omnis iste natus error volup validate your ideas.</div>
						<a href="services-detail.html" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>
					</div>
				</div>
				
				{/* <!-- Service Block --> */}
				<div class="service-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="icon-box">
							<span class="icon flaticon-coding"></span>
						</div>
						<h5><a href="services-detail.html">Web Development</a></h5>
						<div class="text">Sed ut perspiciatis unde omnis iste natus error volup validate your ideas.</div>
						<a href="services-detail.html" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>
					</div>
				</div>
				
				{/* <!-- Service Block --> */}
				<div class="service-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="icon-box">
							<span class="icon flaticon-laptop"></span>
						</div>
						<h5><a href="services-detail.html">Database Security</a></h5>
						<div class="text">Sed ut perspiciatis unde omnis iste natus error volup validate your ideas.</div>
						<a href="services-detail.html" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>
					</div>
				</div>
				
			</div>
		</div>
	</section>
</div>
  );
};

export default Service1;
