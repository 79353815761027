import React from 'react';
const Banner = ({ }) => {
  return (
    
    <section className="banner-section-three">
      <div className="main-slider-carousel owl-carousel owl-theme">
        
        {/* Slide 1 */}
        <div className="slide" style={{ backgroundImage: "url(assets/images/main-slider/image-2.jpg)" }}>
          <div className="color-layer"></div>
          <div className="pattern-layer-one" style={{ backgroundImage: "url(assets/images/main-slider/pattern-7.png)" }}></div>
          <div className="auto-container">
            
            {/* Content Column */}
            <div className="content-column clearfix">
              <div className="inner-column">
                <h1>We Provide Truly <br /> Here Prominent <br /> IT Solutions.</h1>
                <div className="text">
                  Collecting event old above shy bed favour <br /> income has stuff since.
                </div>
                <div className="button-box">
                  <a href="contact.html" className="theme-btn btn-style-one">
                    <span className="txt">Learn More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Slide 2 */}
        <div className="slide" style={{ backgroundImage: "url(assets/images/main-slider/image-2.jpg)" }}>
          <div className="color-layer"></div>
          <div className="pattern-layer-one" style={{ backgroundImage: "url(assets/images/main-slider/pattern-7.png)" }}></div>
          <div className="auto-container">
            
            {/* Content Column */}
            <div className="content-column clearfix">
              <div className="inner-column">
                <h1>We Provide Truly <br /> Here Prominent <br /> IT Solutions.</h1>
                <div className="text">
                  Collecting event old above shy bed favour <br /> income has stuff since.
                </div>
                <div className="button-box">
                  <a href="contact.html" className="theme-btn btn-style-one">
                    <span className="txt">Learn More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Slide 3 */}
        <div className="slide" style={{ backgroundImage: "url(assets/images/main-slider/image-2.jpg)" }}>
          <div className="color-layer"></div>
          <div className="pattern-layer-one" style={{ backgroundImage: "url(assets/images/main-slider/pattern-7.png)" }}></div>
          <div className="auto-container">
            
            {/* Content Column */}
            <div className="content-column clearfix">
              <div className="inner-column">
                <h1>We Provide Truly <br /> Here Prominent <br /> IT Solutions.</h1>
                <div className="text">
                  Collecting event old above shy bed favour <br /> income has stuff since.
                </div>
                <div className="button-box">
                  <a href="contact.html" className="theme-btn btn-style-one">
                    <span className="txt">Learn More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
};

export default Banner;
