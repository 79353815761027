import React from 'react';
import pattern4 from '../assets/images/background/pattern-4.png'; 
import testimonialIcon_1 from '../assets/images/resource/testimonial-icon-1.png'; 
import testimonialIcon_2 from '../assets/images/resource/testimonial-icon-2.png'; 
import Slider from 'react-slick';

const RecentProject = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            {/* <!-- Testimonial Section --> */}
            {/* <!-- figma,Steelart section  --> */}
            <section className="testimonial-section">
                <div className="auto-container">
                    {/* <!-- Sec Title --> */}
                    <div className="sec-title">
                        <div className="clearfix">
                            <div className="pull-left">
                                <div className="title">LATEST CASE STUDIES</div>
                                <h2>Reads Now Our Recent <br /> Projects Studies</h2>
                            </div>
                            <div className="pull-right">
                                <div className="text">Our goal is to help our companies maintain or achieve best- in-class <br /> positions in their respective industries and our team works.</div>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-carousel owl-carousel owl-theme">
                        {/* <!-- Testimonial Block --> */}
                        <div className="testimonial-block">
                            <div className="inner-box" style={{ backgroundImage: `url(${pattern4})` }}>
                                <div className="upper-box">
                                    <div className="icon">
                                        <img src={testimonialIcon_1} alt="Testimonial Icon 1" />
                                    </div>
                                    <h4>Steelart</h4>
                                    <div className="designation">Client of Company</div>
                                </div>
                                <div className="text">"Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."</div>
                            </div>
                        </div>
                        
                        {/* <!-- Testimonial Block --> */}
                        <div className="testimonial-block">
                            <div className="inner-box" style={{ backgroundImage: `url(${pattern4})` }}>
                                <div className="upper-box">
                                    <div className="icon">
                                        <img src={testimonialIcon_2} alt="Testimonial Icon 2" />
                                    </div>
                                    <h4>Figma Skills</h4>
                                    <div className="designation">Client of Company</div>
                                </div>
                                <div className="text">"Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."</div>
                            </div>
                        </div>
                        
                        {/* <!-- Testimonial Block --> */}
                        <div className="testimonial-block">
                            <div className="inner-box" style={{ backgroundImage: `url(${pattern4})` }}>
                                <div className="upper-box">
                                    <div className="icon">
                                        <img src={testimonialIcon_1} alt="Testimonial Icon 1" />
                                    </div>
                                    <h4>Steelart</h4>
                                    <div className="designation">Client of Company</div>
                                </div>
                                <div className="text">"Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."</div>
                            </div>
                        </div>
                        
                        {/* <!-- Testimonial Block --> */}
                        <div className="testimonial-block">
                            <div className="inner-box" style={{ backgroundImage: `url(${pattern4})` }}>
                                <div className="upper-box">
                                    <div className="icon">
                                        <img src={testimonialIcon_2} alt="Testimonial Icon 2" />
                                    </div>
                                    <h4>Figma Skills</h4>
                                    <div className="designation">Client of Company</div>
                                </div>
                                <div className="text">"Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."</div>
                            </div>
                        </div>
                        
                        {/* <!-- Testimonial Block --> */}
                        <div className="testimonial-block">
                            <div className="inner-box" style={{ backgroundImage: `url(${pattern4})` }}>
                                <div className="upper-box">
                                    <div className="icon">
                                        <img src={testimonialIcon_1} alt="Testimonial Icon 1" />
                                    </div>
                                    <h4>Steelart</h4>
                                    <div className="designation">Client of Company</div>
                                </div>
                                <div className="text">"Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."</div>
                            </div>
                        </div>
                        
                        {/* <!-- Testimonial Block --> */}
                        <div className="testimonial-block">
                            <div className="inner-box" style={{ backgroundImage: `url(${pattern4})` }}>
                                <div className="upper-box">
                                    <div className="icon">
                                        <img src={testimonialIcon_2} alt="Testimonial Icon 2" />
                                    </div>
                                    <h4>Figma Skills</h4>
                                    <div className="designation">Client of Company</div>
                                </div>
                                <div className="text">"Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."</div>
                            </div>
                        </div>
                    </div>
                    <div className="lower-text">More than 5k customers share their thoughts to us. . . <a href="testimonial.html">Read All Reviews</a></div>
                </div>
            </section>
        </>
    );
};

export default RecentProject;
