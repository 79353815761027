import React from 'react';
import galleryImage_1 from '../assets/images/gallery/1.jpg';
import galleryImage_2 from '../assets/images/gallery/2.jpg';
import galleryImage_3 from '../assets/images/gallery/3.jpg';
import galleryImage_4 from '../assets/images/gallery/4.jpg';
import galleryImage_5 from '../assets/images/gallery/5.jpg';

const Project = ({ }) => {
    return (
      <div>
	{/* <!-- Cases Section -->
	<!-- Projects -->
	<!-- Reads Now Our Recent Projects --> */}
	<section class="cases-section">
		<div class="auto-container">
			{/* <!-- Sec Title --> */}
			<div class="sec-title centered">
				<div class="title">LATEST CASE STUDIES</div>
				<h2>Reads Now Our Recent <br/> Projects Studies</h2>
			</div>
			<div class="row clearfix">
				{/* <!-- Case Block --> */}
				<div class="case-block col-lg-4 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<img src={galleryImage_1} alt="" />
							<div class="overlay-box">
								<a href={galleryImage_1} data-fancybox="case" data-caption="" class="search-icon"><span class="icon flaticon-search"></span></a>
								<div class="content">
									<h4><a href="projects-detail.html">Social Media App</a></h4>
									<div class="category">Ideas / Technology</div>
								</div>
								<a href="projects-detail.html" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>
							</div>
						</div>
					</div>
				</div>
				
				{/* <!-- Case Block --> */}
				<div class="case-block col-lg-4 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<img src={galleryImage_2} alt="" />
							<div class="overlay-box">
								<a href={galleryImage_2} data-fancybox="case" data-caption="" class="search-icon"><span class="icon flaticon-search"></span></a>
								<div class="content">
									<h4><a href="projects-detail.html">Social Media App</a></h4>
									<div class="category">Ideas / Technology</div>
								</div>
								<a href="projects-detail.html" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>
							</div>
						</div>
					</div>
				</div>
				
				{/* <!-- Case Block --> */}
				<div class="case-block col-lg-4 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<img src= {galleryImage_3}alt="" />
							<div class="overlay-box">
								<a href={galleryImage_3} data-fancybox="case" data-caption="" class="search-icon"><span class="icon flaticon-search"></span></a>
								<div class="content">
									<h4><a href="projects-detail.html">Social Media App</a></h4>
									<div class="category">Ideas / Technology</div>
								</div>
								<a href="projects-detail.html" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>
							</div>
						</div>
					</div>
				</div>
				
				{/* <!-- Case Block --> */}
				<div class="case-block col-lg-6 col-md-12 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<img src={galleryImage_4} alt="" />
							<div class="overlay-box">
								<a href={galleryImage_4} data-fancybox="case" data-caption="" class="search-icon"><span class="icon flaticon-search"></span></a>
								<div class="content">
									<h4><a href="projects-detail.html">Social Media App</a></h4>
									<div class="category">Ideas / Technology</div>
								</div>
								<a href="projects-detail.html" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>
							</div>
						</div>
					</div>
				</div>
				
				{/* <!-- Case Block --> */}
				<div class="case-block col-lg-6 col-md-12 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<img src={galleryImage_5} alt="" />
							<div class="overlay-box">
								<a href={galleryImage_5} data-fancybox="case" data-caption="" class="search-icon"><span class="icon flaticon-search"></span></a>
								<div class="content">
									<h4><a href="projects-detail.html">Social Media App</a></h4>
									<div class="category">Ideas / Technology</div>
								</div>
								<a href="projects-detail.html" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			
			<div class="section-text">We Have Done More Than 1K Projects in Last 3 Years, With 100% Satisfaction.</div>
			
			<div class="btn-box text-center">
				<a href="#" class="theme-btn btn-style-three"><span class="txt">View All</span></a>
			</div>
			
		</div>
	</section>
	{/* <!-- End Cases Section --> */}
</div>
  );
};
export default Project;
