import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoSmall from '../assets/images/logo-small.png'; 
import logo from '../assets/images/logo.png';
import Sidebar from './Sidebar';

const Header = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <div>
      <header className="main-header header-style-one">
        {/* Header Top */}
        <div className="header-top">
          <div className="auto-container">
            <div className="clearfix">
              {/* Top Left */}
              <div className="top-left">
                <ul className="info-list">
                  <li><a href="mailto:info@webmail.com"><i className="fa fa-envelope"></i> info@webmail.com</a></li>
                  <li><a href="tel:786-875-864-75"><i className="fa fa-phone"></i> 786 875 864 75</a></li>
                </ul>
              </div>
              {/* Top Right */}
              <div className="top-right pull-right">
                <ul className="social-box">
                  <li><a href="#" className="fa fa-facebook-f"></a></li>
                  <li><a href="#" className="fa fa-twitter"></a></li>
                  <li><a href="#" className="fa fa-dribbble"></a></li>
                  <li><a href="#" className="fa fa-google"></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Header Upper */}
        <div className="header-upper">
          <div className="auto-container clearfix">
            <div className="pull-left logo-box">
              <div className="logo"><a href="index.html"><img src={logo} alt="" title=""/></a></div>
            </div>
            <div className="nav-outer clearfix">
              <div className="mobile-nav-toggler"><span className="icon flaticon-menu"></span></div>
              <nav className="main-menu navbar-expand-md">
                <div className="navbar-header">
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>
                <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                  <ul className="navigation clearfix">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li className="dropdown">
                      <Link to="#">Services</Link>
                      <ul>
                        <li><Link to="/service">Services</Link></li>
                        <li><Link to="/serviceDetails">Services Detail</Link></li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <Link to="#">Projects</Link>
                      <ul>
                        <li><Link to="/project">Projects</Link></li>
                        <li><Link to="/projectDetails">Projects Detail</Link></li>
                      </ul>
                    </li>
                    <li><Link to="/contact">Contact us</Link></li>
                  </ul>
                </div>
              </nav>
              <div className="outer-box clearfix">
                <div className="search-box-btn search-box-outer"><span className="icon fa fa-search"></span></div>
                {/* Sidebar toggle button */}
                <div className="nav-btn navSidebar-button" onClick={toggleSidebar}>
                  <span className="icon flaticon-menu-2"></span>
                </div>
                <div className="btn-box">
                  <Link to="/contact" className="theme-btn btn-style-one"><span className="txt">Free Consulting</span></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-header">
          <div className="auto-container clearfix">
            <div className="logo pull-left">
              <a href="index.html" title=""><img src={logoSmall} alt="" title=""/></a>
            </div>
            <div className="pull-right">
              <nav className="main-menu"></nav>
              <div className="outer-box clearfix">
                <div className="cart-box">
                  <div className="dropdown">
                    <button className="cart-box-btn dropdown-toggle" type="button" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="flaticon-shopping-bag-1"></span><span className="total-cart">2</span>
                    </button>
                    <div className="dropdown-menu pull-right cart-panel" aria-labelledby="dropdownMenu"></div>
                  </div>
                </div>
                <div className="search-box-btn search-box-outer"><span className="icon fa fa-search"></span></div>
                <div className="nav-btn navSidebar-button" onClick={toggleSidebar}><span className="icon flaticon-menu"></span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-menu">
          <div className="menu-backdrop"></div>
          <div className="close-btn"><span className="icon flaticon-multiply"></span></div>
          <nav className="menu-box">
            <div className="nav-logo">
              <a href="index.html"><img src="images/logo.png" alt="" title=""/></a>
            </div>
            <div className="menu-outer"></div>
          </nav>
        </div>
      </header>
      {/* Sidebar Component */}
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default Header;
