import React from 'react';
import { Link } from 'react-router-dom'; 
import pattern_16 from '../assets/images/background/pattern-16.png';
import gallery_6 from '../assets/images/gallery/6.jpg';
import gallery_7 from '../assets/images/gallery/7.jpg';
import gallery_8 from '../assets/images/gallery/8.jpg';
import gallery_9 from '../assets/images/gallery/9.jpg';
import gallery_10 from '../assets/images/gallery/10.jpg';
import gallery_14 from '../assets/images/gallery/14.jpg';
import { Carousel, Container } from 'react-bootstrap';

const projectDetails = () => {
    return (
<>
    <section class="page-title">
		<div class="pattern-layer-one" style={{ backgroundImage: `url(${pattern_16})` }}></div>
    	<div class="auto-container">
			<h2>Project Detail</h2>
			<ul class="page-breadcrumb">
				<li><Link to="/">home</Link></li>
				<li>Project Detail</li>
			</ul>
        </div>
    </section>
	<section class="project-detail-section">
		<div class="auto-container">
			<div class="upper-section">
				<div class="row clearfix">	
					<div class="image-column col-lg-8 col-md-12 col-sm-12">
						<div class="inner-column">		
							<div class="sec-title">
								<div class="title">IT-Solutions</div>
								<h2>Proud Projects That Make Us Stand Out</h2>
							</div>
							<div class="image">
								<img src={gallery_14} alt="" />
							</div>
						</div>
					</div>
					<div class="info-column col-lg-4 col-md-12 col-sm-12">
						<div class="inner-column">
							<h4>Project Info</h4>
							<div class="text">Etiam volutpat ultricies est id volutpat. Maecenas finibus risus purus, id lobortis urna rutrum id. Sed pellent esquevert pharetra consequat</div>
							<ul class="info-list">
								<li><span class="icon flaticon-user"></span><strong>Client: </strong>John Amanda</li>
								<li><span class="icon fa fa-bookmark-o"></span><strong>Category: </strong>Business, Campaign</li>
								<li><span class="icon flaticon-timetable"></span><strong>Date: </strong>6 June 20</li>
								<li><span class="icon fa fa-globe"></span><strong>Website: </strong>Globex.com</li>
								<li><span class="icon fa fa-map-marker"></span><strong>Location: </strong>New York</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="lower-section">
				<h3>Innovative Interfaces</h3>
				<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. remaining essentially unchanged.</p>
				<h3>You May Also Know How it Uses In IT Field?</h3>
				<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. remaining essentially unchanged.</p>
				<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
				<h3>Project Result & Benefits of Project</h3>
				<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Many desktop publishing packages and web page editors now.</p>
				<div class="buttons-box clearfix">
					<div class="pull-left">
						<Link to="#" class="theme-btn btn-style-three"><span class="txt"><i class="fa fa-angle-double-left"></i>&nbsp; Previous</span></Link>
					</div>
					<div class="pull-right">
						<Link to="#" class="theme-btn btn-style-three"><span class="txt">Send Massage &nbsp;<i class="fa fa-angle-double-right"></i></span></Link>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="cases-section-two">
		<div class="auto-container">
			<div class="sec-title">
				<div class="title">LATEST CASE STUDIES</div>
				<h2>Reads Our Recent <br/> Case Studies</h2>
			</div>
		</div>	
		<div class="outer-container">
			<div class="five-item-carousel owl-theme ">

<Carousel>
    <Carousel.Item>
        <div className="carousel-inner-grid">
            <div className="case-block-two custom-carousel-width-margin">
                <div className="inner-box">
                    <div className="image">
                        <img src={gallery_6} alt="" />
                        <div className="overlay-box">
                            <a href={gallery_6} data-fancybox="case-2" data-caption="" className="search-icon">
                                <span className="icon flaticon-search"></span>
                            </a>
                            <Link to="/projects-detail" className="arrow flaticon-long-arrow-pointing-to-the-right"></Link>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h5><Link to="/projects-detail">Social Media App</Link></h5>
                        <div className="category">Consulting / Technology</div>
                    </div>
                </div>
            </div>

            <div className="case-block-two custom-carousel-width-margin">
                <div className="inner-box">
                    <div className="image">
                        <img src={gallery_7} alt="" />
                        <div className="overlay-box">
                            <a href={gallery_7} data-fancybox="case-2" data-caption="" className="search-icon">
                                <span className="icon flaticon-search"></span>
                            </a>
                            <Link to="/projects-detail" className="arrow flaticon-long-arrow-pointing-to-the-right"></Link>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h5><Link to="/projects-detail">Social Media App</Link></h5>
                        <div className="category">Ideas / Technology</div>
                    </div>
                </div>
            </div>

            <div className="case-block-two custom-carousel-width-margin">
                <div className="inner-box">
                    <div className="image">
                        <img src={gallery_8} alt="" />
                        <div className="overlay-box">
                            <a href={gallery_8} data-fancybox="case-2" data-caption="" className="search-icon">
                                <span className="icon flaticon-search"></span>
                            </a>
                            <Link to="/projects-detail" className="arrow flaticon-long-arrow-pointing-to-the-right"></Link>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h5><Link to="/projects-detail">eCommerce Website</Link></h5>
                        <div className="category">Design / Development</div>
                    </div>
                </div>
            </div>

            <div className="case-block-two custom-carousel-width-margin">
                <div className="inner-box">
                    <div className="image">
                        <img src={gallery_9} alt="" />
                        <div className="overlay-box">
                            <a href={gallery_9} data-fancybox="case-2" data-caption="" className="search-icon">
                                <span className="icon flaticon-search"></span>
                            </a>
                            <Link to="/projects-detail" className="arrow flaticon-long-arrow-pointing-to-the-right"></Link>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h5><Link to="/projects-detail">Develop Your IT Business</Link></h5>
                        <div className="category">Project / IT Business</div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>

    <Carousel.Item>
        <div className="carousel-inner-grid">
            <div className="case-block-two custom-carousel-width-margin">
                <div className="inner-box">
                    <div className="image">
                        <img src={gallery_10} alt="" />
                        <div className="overlay-box">
                            <a href={gallery_10} data-fancybox="case-2" data-caption="" className="search-icon">
                                <span className="icon flaticon-search"></span>
                            </a>
                            <Link to="/projects-detail" className="arrow flaticon-long-arrow-pointing-to-the-right"></Link>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h5><Link to="/projects-detail">Manage Your IT Solution</Link></h5>
                        <div className="category">IT Solution</div>
                    </div>
                </div>
            </div>

            <div className="case-block-two custom-carousel-width-margin">
                <div className="inner-box">
                    <div className="image">
                        <img src={gallery_6} alt="" />
                        <div className="overlay-box">
                            <a href={gallery_6} data-fancybox="case-2" data-caption="" className="search-icon">
                                <span className="icon flaticon-search"></span>
                            </a>
                            <Link to="/projects-detail" className="arrow flaticon-long-arrow-pointing-to-the-right"></Link>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h5><Link to="/projects-detail">Social Media App</Link></h5>
                        <div className="category">Consulting / Technology</div>
                    </div>
                </div>
            </div>

            <div className="case-block-two custom-carousel-width-margin">
                <div className="inner-box">
                    <div className="image">
                        <img src={gallery_7} alt="" />
                        <div className="overlay-box">
                            <a href={gallery_7} data-fancybox="case-2" data-caption="" className="search-icon">
                                <span className="icon flaticon-search"></span>
                            </a>
                            <Link to="/projects-detail" className="arrow flaticon-long-arrow-pointing-to-the-right"></Link>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h5><Link to="/projects-detail">Social Media App</Link></h5>
                        <div className="category">Ideas / Technology</div>
                    </div>
                </div>
            </div>

            <div className="case-block-two custom-carousel-width-margin">
                <div className="inner-box">
                    <div className="image">
                        <img src={gallery_8} alt="" />
                        <div className="overlay-box">
                            <a href={gallery_8} data-fancybox="case-2" data-caption="" className="search-icon">
                                <span className="icon flaticon-search"></span>
                            </a>
                            <Link to="/projects-detail" className="arrow flaticon-long-arrow-pointing-to-the-right"></Link>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h5><Link to="/projects-detail">eCommerce Website</Link></h5>
                        <div className="category">Design / Development</div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>

    <Carousel.Item>
        <div className="carousel-inner-grid">
            <div className="case-block-two custom-carousel-width-margin">
                <div className="inner-box">
                    <div className="image">
                        <img src={gallery_9} alt="" />
                        <div className="overlay-box">
                            <a href={gallery_9} data-fancybox="case-2" data-caption="" className="search-icon">
                                <span className="icon flaticon-search"></span>
                            </a>
                            <Link to="/projects-detail" className="arrow flaticon-long-arrow-pointing-to-the-right"></Link>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h5><Link to="/projects-detail">Develop Your IT Business</Link></h5>
                        <div className="category">Project / IT Business</div>
                    </div>
                </div>
            </div>

            <div className="case-block-two custom-carousel-width-margin">
                <div className="inner-box">
                    <div className="image">
                        <img src={gallery_10} alt="" />
                        <div className="overlay-box">
                            <a href={gallery_10} data-fancybox="case-2" data-caption="" className="search-icon">
                                <span className="icon flaticon-search"></span>
                            </a>
                            <Link to="/projects-detail" className="arrow flaticon-long-arrow-pointing-to-the-right"></Link>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h5><Link to="/projects-detail">Manage Your IT Solution</Link></h5>
                        <div className="category">IT Solution</div>
                    </div>
                </div>
            </div>

            <div className="case-block-two custom-carousel-width-margin">
                <div className="inner-box">
                    <div className="image">
                        <img src={gallery_6} alt="" />
                        <div className="overlay-box">
                            <a href={gallery_6} data-fancybox="case-2" data-caption="" className="search-icon">
                                <span className="icon flaticon-search"></span>
                            </a>
                            <Link to="/projects-detail" className="arrow flaticon-long-arrow-pointing-to-the-right"></Link>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h5><Link to="/projects-detail">Social Media App</Link></h5>
                        <div className="category">Consulting / Technology</div>
                    </div>
                </div>
            </div>

            <div className="case-block-two custom-carousel-width-margin">
                <div className="inner-box">
                    <div className="image">
                        <img src={gallery_7} alt="" />
                        <div className="overlay-box">
                            <a href={gallery_7} data-fancybox="case-2" data-caption="" className="search-icon">
                                <span className="icon flaticon-search"></span>
                            </a>
                            <Link to="/projects-detail" className="arrow flaticon-long-arrow-pointing-to-the-right"></Link>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h5><Link to="/projects-detail">Social Media App</Link></h5>
                        <div className="category">Ideas / Technology</div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>
</Carousel>
			</div>	
		</div>
		
	</section>
</>

);
};

export default projectDetails;