import React from 'react';
import newsImag1 from '../assets/images/resource/news-1.jpg';
import newsImag2 from '../assets/images/resource/news-2.jpg';
import newsImag3 from '../assets/images/resource/news-3.jpg';

const Blog = ({ }) => {
    return (
      <>
	
	{/* <!-- News Section -->
	<!-- OUR BLOG --> */}
	<section class="news-section">
		<div class="auto-container">
			{/* <!-- Sec Title --> */}
			<div class="sec-title centered">
				<div class="title">Our Blog</div>
				<h2>Click Out Our <br/> Latest News & Artical</h2>
			</div>
			<div class="row clearfix">
				
				{/* <!-- News Block --> */}
				<div class="news-block col-lg-4 col-md-6 col-sm-12">
					<div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
						<div class="image">
							<a href="news-detail.html"><img src={newsImag1} alt="News Image" /></a>
						</div>
						<div class="lower-content">
							<div class="post-date">22 <span>DEC</span></div>
							<ul class="post-meta">
								<li><span class="icon flaticon-comment"></span>3 comments</li>
								<li><span class="icon flaticon-user"></span>Admin</li>
							</ul>
							<h4><a href="news-detail.html">Define World Best IT Solution Technology</a></h4>
							<div class="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has ...</div>
							<a class="read-more" href="news-detail.html">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>
						</div>
					</div>
				</div>
				
				{/* <!-- News Block --> */}
				<div class="news-block col-lg-4 col-md-6 col-sm-12">
					<div class="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
						<div class="image">
							<a href="news-detail.html"><img src={newsImag2} alt="" /></a>
						</div>
						<div class="lower-content">
							<div class="post-date">15 <span>DEC</span></div>
							<ul class="post-meta">
								<li><span class="icon flaticon-comment"></span>3 comments</li>
								<li><span class="icon flaticon-user"></span>Admin</li>
							</ul>
							<h4><a href="news-detail.html">You Must Try 20 Secret Of Digital Transform</a></h4>
							<div class="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has ...</div>
							<a class="read-more" href="news-detail.html">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>
						</div>
					</div>
				</div>
				
				{/* <!-- News Block --> */}
				<div class="news-block col-lg-4 col-md-6 col-sm-12">
					<div class="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
						<div class="image">
							<a href="news-detail.html"><img src={newsImag3} alt="" /></a>
						</div>
						<div class="lower-content">
							<div class="post-date">10 <span>oct</span></div>
							<ul class="post-meta">
								<li><span class="icon flaticon-comment"></span>3 comments</li>
								<li><span class="icon flaticon-user"></span>Admin</li>
							</ul>
							<h4><a href="news-detail.html">Digital Conference Of IT Tech Events in 2020</a></h4>
							<div class="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has ...</div>
							<a class="read-more" href="news-detail.html">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</section>
	{/* <!-- End News Section --> */}


      </>

);
};

export default Blog;