import React from 'react';
import pattern7 from '../assets/images/background/pattern-7.png';
import pattern8 from '../assets/images/background/pattern-8.png';
import logoImage from '../assets/images/logo-2.png'; 
import postThumbImage_3 from '../assets/images/resource/post-thumb-3.jpg'; 
import postThumbImage_4 from '../assets/images/resource/post-thumb-4.jpg'; 

const Footer = ({ }) => {
    return (
<>
{/* <!-- Main Footer --> */}
    <footer class="main-footer">
	<div className="pattern-layer-one" style={{ backgroundImage: `url(${pattern7})` }}></div>
	<div className="pattern-layer-one" style={{ backgroundImage: `url(${pattern8})` }}></div>	
		{/* <!--Waves end--> */}
    	<div class="auto-container">
        	{/* <!--Widgets Section--> */}
            <div class="widgets-section">
            	<div class="row clearfix">
                    {/* <!-- Column --> */}
                    <div class="big-column col-lg-6 col-md-12 col-sm-12">
						<div class="row clearfix">
                        	{/* <!-- Footer Column --> */}
                            <div class="footer-column col-lg-7 col-md-6 col-sm-12">
                                <div class="footer-widget logo-widget">
									<div class="logo">
										<a href="index.html"><img src={logoImage} alt="Logo" /></a>
									</div>
									<div class="text">We are the best world Information Technology Company. Providing the highest quality in hardware & Network solutions. About more than 25 years of experience and 1000 of innovative achievements.</div>
									{/* <!-- Social Box --> */}
									<ul class="social-box">
										<li><a href="#" class="fa fa-facebook-f"></a></li>
										<li><a href="#" class="fa fa-linkedin"></a></li>
										<li><a href="#" class="fa fa-twitter"></a></li>
										<li><a href="#" class="fa fa-google"></a></li>
									</ul>
								</div>
							</div>
							
							{/* <!-- Footer Column --> */}
                            <div class="footer-column col-lg-5 col-md-6 col-sm-12">
                                <div class="footer-widget links-widget">
									<h5>Quick Links</h5>
									<ul class="list-link">
										<li><a href="">Managed IT services</a></li>
										<li><a href="">Cloud Services</a></li>
										<li><a href="">IT support & helpdesk</a></li>
										<li><a href="">Cyber security</a></li>
										<li><a href="">Custom Software</a></li>
										<li><a href="">Free Consultation</a></li>
										<li><a href="">Our Business Growth</a></li>
									</ul>
								</div>
							</div>
							
						</div>
					</div>
					
					{/* <!-- Column --> */}
                    <div class="big-column col-lg-6 col-md-12 col-sm-12">
						<div class="row clearfix">
							{/* <!-- Footer Column --> */}
							<div class="footer-column col-lg-6 col-md-6 col-sm-12">
								<div class="footer-widget news-widget">
									<h5>Resent Post</h5>
									{/* <!-- Footer Column --> */}
									<div class="widget-content">
										<div class="post">
											<div class="thumb"><a href="news-detail.html"><img src={postThumbImage_3} alt="" /></a></div>
											<h6><a href="news-detail.html">Define World Best IT Solution Technology</a></h6>
											<span class="date">May 01, 2020</span>
										</div>
										<div class="post">
											<div class="thumb"><a href="news-detail.html"><img src={postThumbImage_4} alt="" /></a></div>
											<h6><a href="news-detail.html">PHP Frameworks You Need To Use Anywhere</a></h6>
											<span class="date">May 01, 2020</span>
										</div>
									</div>
								</div>
							</div>
							
							{/* <!-- Footer Column --> */}
							<div class="footer-column col-lg-6 col-md-6 col-sm-12">
								<div class="footer-widget contact-widget">
									<h5>Contact Us</h5>
									<ul>
										<li>
											<span class="icon flaticon-placeholder-2"></span>
											<strong>Address</strong>
											125, Suitland Street, USA
										</li>
										<li>
											<span class="icon flaticon-phone-call"></span>
											<strong>Phone</strong>
											<a href="tel:+786-875-864-75">+ 786 875 864 75</a>
										</li>
										<li>
											<span class="icon flaticon-email-1"></span>
											<strong>E-Mail</strong>
											<a href="mailto:support@globex.com">support@globex.com</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			{/* <!-- Footer Bottom --> */}
			<div class="footer-bottom">
				<div class="auto-container">
					<div class="row clearfix">
						{/* <!-- Column --> */}
						<div class="column col-lg-6 col-md-12 col-sm-12">
							<div class="copyright">Copyright &copy; 2020 Engitech by expert-themes. All Rights Reserved.</div>
						</div>
						{/* <!-- Column --> */}
						<div class="column col-lg-6 col-md-12 col-sm-12">
							<ul class="footer-nav">
								<li><a href="#">About Us</a></li>
								<li><a href="#">Services</a></li>
								<li><a href="#">Privacy</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>

</>
);
};

export default Footer;