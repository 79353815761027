import React from 'react';
import background2 from '../assets/images/background/2.jpg';
import teamImage1 from '../assets/images/resource/team-1.jpg';
import teamImage2 from '../assets/images/resource/team-2.jpg';
import teamImage3 from '../assets/images/resource/team-3.jpg';
import teamImage4 from '../assets/images/resource/team-4.jpg';


const RecentProject = ({ }) => {
    return (
<>
{/* <!-- Team Section --> */}
	<section className="team-section" style={{ backgroundImage: `url(${background2})` }}>
		<div class="auto-container">
			{/* <!-- Sec Title --> */}
			<div class="sec-title">
				<div class="clearfix">
					<div class="pull-left">
						<div class="title">OUR DEDICATED TEAM</div>
						<h2>We have Large No <br/> of Expert Team Member</h2>
					</div>
					<div class="pull-right">
						<div class="text">Our goal is to help our companies maintain or achieve best- in-class <br/> positions in their respective industries and our team works.</div>
					</div>
				</div>
			</div>
			<div class="clearfix">
				
				{/* <!-- Team Block --> */}
				<div class="team-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="image">
						<a href="team-detail.html">
						<img src={teamImage1} alt="" />
						</a>
						</div>
						<div class="lower-box">
							{/* <!-- Social Box --> */}
							<ul class="social-box">
								<li><a href="#" class="fa fa-facebook-f"></a></li>
								<li><a href="#" class="fa fa-twitter"></a></li>
								<li><a href="#" class="fa fa-dribbble"></a></li>
							</ul>
							<div class="content">
								<h5><a href="team-detail.html">Jennifer Garcia</a></h5>
								<div class="designation">Consultant Officer</div>
							</div>
						</div>
					</div>
				</div>
				
				{/* <!-- Team Block --> */}
				<div class="team-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<a href="team-detail.html">    <img src={teamImage2} alt="" /></a>
						</div>
						<div class="lower-box">
							{/* <!-- Social Box --> */}
							<ul class="social-box">
								<li><a href="#" class="fa fa-facebook-f"></a></li>
								<li><a href="#" class="fa fa-twitter"></a></li>
								<li><a href="#" class="fa fa-dribbble"></a></li>
							</ul>
							<div class="content">
								<h5><a href="team-detail.html">Robert Liam</a></h5>
								<div class="designation">Web Designer</div>
							</div>
						</div>
					</div>
				</div>
				
				{/* <!-- Team Block --> */}
				<div class="team-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<a href="team-detail.html">    <img src={teamImage3} alt="" /></a>
						</div>
						<div class="lower-box">
							{/* <!-- Social Box --> */}
							<ul class="social-box">
								<li><a href="#" class="fa fa-facebook-f"></a></li>
								<li><a href="#" class="fa fa-twitter"></a></li>
								<li><a href="#" class="fa fa-dribbble"></a></li>
							</ul>
							<div class="content">
								<h5><a href="team-detail.html">Jassica Ethan</a></h5>
								<div class="designation">Project Manager</div>
							</div>
						</div>
					</div>
				</div>
				
				{/* <!-- Team Block --> */}
				<div class="team-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="image">
							<a href="team-detail.html">    <img src={teamImage4} alt="" /></a>
						</div>
						<div class="lower-box">
							{/* <!-- Social Box --> */}
							<ul class="social-box">
								<li><a href="#" class="fa fa-facebook-f"></a></li>
								<li><a href="#" class="fa fa-twitter"></a></li>
								<li><a href="#" class="fa fa-dribbble"></a></li>
							</ul>
							<div class="content">
								<h5><a href="team-detail.html">Adaim Mrala</a></h5>
								<div class="designation">IT Service Officer</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</section>
	{/* <!-- End Team Section --> */}
</>
        );
    };
    export default RecentProject;