import React from 'react';
import Icon_img from '../assets/images/icons/play-icon.png'; // Make sure to import your play icon image
import globeImage from '../assets/images/icons/globe.png';
import patternImage from '../assets/images/background/pattern-1.png';
import about_1 from '../assets/images/resource/about-1.jpg';
import about_2 from '../assets/images/resource/about-2.jpg';
import about_3 from '../assets/images/resource/about-3.jpg';
import about_4 from '../assets/images/resource/about-4.jpg';
import featureImage from '../assets/images/resource/feature-1.jpg';
import featureImage_2 from '../assets/images/resource/feature-2.jpg';

const About = ({ }) => {
    return (
      < >
{/* <section class="about-section">    yah class se About me top pe space rehti he*/}
<section class="about-section">
		<div class="auto-container">
			{/* <!-- Sec Title --> */}
			<div class="sec-title">
				<div class="title">ABOUT COMPANY</div>
				<h2>You Can not Use Up <br/> Creativity.</h2>
			</div>
			<div class="row clearfix">
				
				{/* <!-- Content Column --> */}
				<div class="content-column col-lg-6 col-md-12 col-sm-12">
					<div class="inner-column">
						<div class="text">Does any industry face a more complex audience journey and marketing sales process than B2B technology? Consider the number of people who influence a sale, the length of the decision-making cycle, the competing interests of the people who purchase, implement, manage, and use the technology. It’s a lot meaningful content here.</div>
						<div class="blocks-outer">
						
							{/* <!-- Feature Block --> */}
							<div class="feature-block">
								<div class="inner-box">
									<div class="icon flaticon-award-1"></div>
									<h6>Experience</h6>
									<div class="feature-text">Our great team of more than 1400 software experts.</div>
								</div>
							</div>
							
							{/* <!-- Feature Block --> */}
							<div class="feature-block">
								<div class="inner-box">
									<div class="icon flaticon-technical-support"></div>
									<h6>Quick Support</h6>
									<div class="feature-text">We’ll help you test bold new ideas while sharing your.</div>
								</div>
							</div>
							
						</div>
						<a href="https://www.youtube.com/watch?v=kxPCFljwJws" className="lightbox-image theme-btn btn-style-one">
						<span className="txt">
							<i className="play-icon">
							<img src={Icon_img} alt="Play Icon" />
							</i>
							&ensp; intro Video
						</span>
						</a>
					</div>
				</div>
				
				{/* <!-- Images Column --> */}
				<div class="images-column col-lg-6 col-md-12 col-sm-12">
				<div className="inner-column" style={{ backgroundImage: `url(${globeImage})` }}>
      			<div className="pattern-layer" style={{ backgroundImage: `url(${patternImage})` }}></div>
						<div class="images-outer parallax-scene-1">
							<div class="image" data-depth="0.10">
								<img src={about_1} alt="" />
							</div>
							<div class="image-two" data-depth="0.30">
								<img src={about_2} alt="" />
							</div>
							<div class="image-three" data-depth="0.20">
								<img src={about_3} alt="" />
							</div>
							<div class="image-four" data-depth="0.30">
								<img src={about_4} alt="" />
							</div>
						</div>
					</div>
					<a href="about.html" class="learn"><span class="arrow flaticon-long-arrow-pointing-to-the-right"></span>Learn More About Company</a>
				</div>
				
			</div>
		</div>
	</section>


    {/* <!-- Featured Section --> */}
	<section class="featured-section">
		<div class="auto-container">
			<div class="row clearfix">
				
				{/* <!-- Featured Block Two --> */}
				<div class="feature-block-two col-lg-6 col-md-12 col-sm-12">
				<div
					className="inner-box wow fadeInLeft"
					data-wow-delay="0ms"
					data-wow-duration="1500ms"
					style={{ backgroundImage: `url(${featureImage})` }}
    			>
						<div class="number">35 +</div>
						<h4>Worldwide Work Pair</h4>
						<div class="text">To succeed, every software solution must be deeply integrated into the existing tech environment..</div>
					</div>
				</div>
				
				{/* <!-- Featured Block Two --> */}
				<div class="feature-block-two col-lg-6 col-md-12 col-sm-12">
				<div
				className="inner-box wow fadeInLeft"
				data-wow-delay="0ms"
				data-wow-duration="1500ms"
				style={{ backgroundImage: `url(${featureImage_2})` }}
				>

						<div class="number">40 k</div>
						<h4>Happy Clients</h4>
						<div class="text">To succeed, every software solution must be deeply integrated into the existing tech environment..</div>
					</div>
				</div>
				
			</div>
		</div>
	</section>
	{/* <!-- End Featured Section --> */}
</>
  );
};

export default About;
