import React from "react";
import { Link } from 'react-router-dom'; 
import pattern_16 from '../assets/images/background/pattern-16.png';
import service_1 from '../assets/images/resource/service-1.jpg';
import service_2 from '../assets/images/resource/service-2.jpg';
import logo_2 from '../assets/images/logo-2.png';
import background_6 from '../assets/images/background/6.jpg';
import service from '../assets/images/resource/service.jpg';
const ServiceDetails = ({ }) => {
    return (
      < >
          <section class="page-title">
		<div class="pattern-layer-one" style={{ backgroundImage: `url(${pattern_16})` }}></div>
    	<div class="auto-container">
			<h2>Service Detail</h2>
			<ul class="page-breadcrumb">
				<li><a href="index.html">home</a></li>
				<li>Services</li>
			</ul>
        </div>
    </section>
   
    <div class="sidebar-page-container">
    	<div class="auto-container">
        	<div class="row clearfix">
                <div class="sidebar-side left-sidebar col-lg-4 col-md-12 col-sm-12">
                	<aside class="sidebar sticky-top">
					
                        <div class="sidebar-widget">
                            <ul class="service-list">
                                <li class="current"><a to="/services-detail"><span class="arrow fa fa-angle-double-right"></span> QA & Testing</a></li>
                                <li><Link to="/services-detail"><span className="arrow fa fa-angle-double-right"></span> UI/UX Design</Link></li>
                                <li><Link to="/services-detail"><span className="arrow fa fa-angle-double-right"></span> Dedicated Team</Link></li>
                                <li><Link to="/services-detail"><span className="arrow fa fa-angle-double-right"></span> Web Development</Link></li>
                                <li><Link to="/services-detail"><span className="arrow fa fa-angle-double-right"></span> IT Counsultancy</Link></li>
                                <li><Link to="/services-detail"><span className="arrow fa fa-angle-double-right"></span> Mobile Development</Link></li>
                            </ul>
                        </div>
						
					
						<div class="sidebar-widget contact-widget">
							<div class="widget-content" style={{ backgroundImage: `url(${service})` }}>
								<div class="border-layer"></div>
								<div class="icon-box flaticon-phone-call"></div>
								<h4>How Can We Help?</h4>
								<div class="text">If you need any help, please <br/> feel free to contact us.</div>
								<ul>
									<li><span class="icon flaticon-call"></span>+1234567890</li>
									<li><span class="icon flaticon-envelope"></span>info@example.com</li>
								</ul>
							</div>
						</div>
						
					</aside>
				</div>
				
		
                <div class="content-side right-sidebar col-lg-8 col-md-12 col-sm-12">
                	<div class="services-detail">
						<div class="inner-box">
							<h2>IT Consultancy</h2>
							<div class="image">
								{/* <img src="images/resource/service-1.jpg" alt="" /> */}
                                <img src={service_1} alt="" />
							</div>
							<p>We help transform the world’s most important businesses into vigorous organizations that anticipate the agile unpredictable, adapt rapidly to disruption and outcompete opposition. We work with clients who not hide from the future but want define clients with high potential and high ambition.</p>
							<p>We help transform the world’s most important businesses into vigorous organizations opposition. We work with clients who not hide from the future but want define clients with high potential and high ambition. We go deep to unlock insight and have courage to act. We bring the right people together to challenge established thinking and drive transformation. We work with our clients to build the capabilities that enable organizations to achieve sustainable advantage.</p>
							<h3>Cyber Security</h3>
							<p>Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac laboris aliquip ex ea commodo consequat duis aute irure.dolor in repre henderit in voluptate velit esse cillum fugiat nulla paria tur excepteur sint cupidatat non proident.</p>
							<div class="image-two">
                            <img src={service_2} alt="" />
								<div class="play-box">
									<Link to="https://www.youtube.com/watch?v=kxPCFljwJws" class="lightbox-image video-box"><span class="fa fa-play"><i class="ripple"></i></span></Link>
								</div>
							</div>
							<h3>Analyzing IT services</h3>
							<p>We help transform the world’s most important businesses into vigorous organizations that anticipate the agile unpredictable, adapt rapidly to disruption and outcompete opposition. We work with clients who not hide from the future but want define clients with high potential and high ambition.</p>
							<p>We help transform the world’s most important businesses into vigorous organizations opposition. We work with clients who not hide from the future but want define clients with high potential and high ambition.</p>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</div>
	

	<section class="info-section" style={{ backgroundImage: `url(${background_6})` }}>
		<div class="auto-container">
			<div class="row clearfix">
				
				
				<div class="logo-column col-lg-3 col-md-6 col-sm-12">
					<div class="inner-column">
						<div class="logo">
							<a href="index.html"><img src={logo_2} alt="" /></a>
						</div>
					</div>
				</div>
				
			
				<div class="info-column col-lg-3 col-md-6 col-sm-12">
					<div class="inner-column">
						<div class="icon-box"><span class="flaticon-pin"></span></div>
						<ul>
							<li><strong>Address</strong></li>
							<li>125, Suitland Street, USA</li>
						</ul>
					</div>
				</div>
				
			
				<div class="info-column col-lg-3 col-md-6 col-sm-12">
					<div class="inner-column">
						<div class="icon-box"><span class="flaticon-phone-call"></span></div>
						<ul>
							<li><strong>Phone</strong></li>
							<li>+ 786 875 864 75</li>
						</ul>
					</div>
				</div>
				
				<div class="info-column col-lg-3 col-md-6 col-sm-12">
					<div class="inner-column">
						<div class="icon-box"><span class="flaticon-email-1"></span></div>
						<ul>
							<li><strong>E-Mail</strong></li>
							<li>support@globex.com</li>
						</ul>
					</div>
				</div>				
			</div>
		</div>
	</section>      
      
      </>
    );
};
export default ServiceDetails;