import React from 'react';
import icon1 from '../assets/images/icons/icon-1.png'; 
import icon2 from '../assets/images/icons/icon-2.png'; 
import icon3 from '../assets/images/icons/icon-3.png'; 
import logo_2 from '../assets/images/logo-2.png';
import background_6 from '../assets/images/background/6.jpg';
import background_4 from '../assets/images/background/4.jpg';

import appointment from '../assets/images/resource/appointment.jpg';
const Service2 = () => {
    const iconOneStyle = {
        backgroundImage: `url(${icon1})`
    };

    const iconTwoStyle = {
        backgroundImage: `url(${icon2})`
    };

    const iconThreeStyle = {
        backgroundImage: `url(${icon3})`
    };

    return (
      <div>
        {/* <!-- Services Section Two --> */}
        <section className="services-section-two ">
          <div className="auto-container">
            <div className="upper-box">
              <div className="icon-one" style={iconOneStyle}></div>
              <div className="icon-two" style={iconTwoStyle}></div>
              <div className="icon-three" style={iconThreeStyle}></div>
              
              {/* <!-- Sec Title --> */}
              <div className="sec-title light centered">
                <div className="title">WHO WE ARE</div>
                <h2>We deal with the aspects of <br/> professional IT Services</h2>
              </div>
            </div>
            <div className="inner-container">
              <div className="row clearfix">
                {/* <!-- Service Block Two --> */}
                <div className="service-block-two col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div className="shape-one"></div>
                    <div className="shape-two"></div>
                    <div className="icon-box">
                      <span className="icon flaticon-coding-1"></span>
                    </div>
                    <h5><a href="services-detail.html">Web Development</a></h5>
                    <div className="text">We carry more than just good coding skills. Our experience makes us stand out from other web development.</div>
                  </div>
                </div>
                
                {/* <!-- Service Block Two --> */}
                <div className="service-block-two col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div className="shape-one"></div>
                    <div className="shape-two"></div>
                    <div className="icon-box">
                      <span className="icon flaticon-mobile-app"></span>
                    </div>
                    <h5><a href="services-detail.html">Mobile Development</a></h5>
                    <div className="text">Create complex enterprise software, ensure reliable software integration, modernize your legacy system.</div>
                  </div>
                </div>
                
                {/* <!-- Service Block Two --> */}
                <div className="service-block-two col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div className="shape-one"></div>
                    <div className="shape-two"></div>
                    <div className="icon-box">
                      <span className="icon flaticon-computer"></span>
                    </div>
                    <h5><a href="services-detail.html">UI/UX Design</a></h5>
                    <div className="text">Build the product you need on time with an experienced team that uses a clear and effective design process.</div>
                  </div>
                </div>
                
                {/* <!-- Service Block Two --> */}
                <div className="service-block-two col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div className="shape-one"></div>
                    <div className="shape-two"></div>
                    <div className="icon-box">
                      <span className="icon flaticon-web"></span>
                    </div>
                    <h5><a href="services-detail.html">QA & Testing</a></h5>
                    <div className="text">Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software.</div>
                  </div>
                </div>
                
                {/* <!-- Service Block Two --> */}
                <div className="service-block-two col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div className="shape-one"></div>
                    <div className="shape-two"></div>
                    <div className="icon-box">
                      <span className="icon flaticon-monitor-2"></span>
                    </div>
                    <h5><a href="services-detail.html">IT Consultancy</a></h5>
                    <div className="text">Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios.</div>
                  </div>
                </div>
                
                {/* <!-- Service Block Two --> */}
                <div className="service-block-two col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div className="shape-one"></div>
                    <div className="shape-two"></div>
                    <div className="icon-box">
                      <span className="icon flaticon-human-resources"></span>
                    </div>
                    <h5><a href="services-detail.html">Dedicated Team</a></h5>
                    <div className="text">Over the past decade, our customers succeeded by leveraging Intellectsoft’s process of building, motivating.</div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Services Section Two --> */}

        <section class="appointment-section style-two">
		<div class="image-layer" style={{ backgroundImage: `url(${background_4})` }}></div>
		<div class="auto-container">
	
			<div class="sec-title light centered">
				<div class="title">CONTACT US</div>
				<h2>Join Us To Get IT Free <br/> Consultations</h2>
			</div>
			<div class="inner-container">
				<div class="row clearfix">				
					<div class="image-column col-lg-6 col-md-12 col-sm-12">
						<div class="inner-column wow slideInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
							<div class="image">
              <img src={appointment} alt="" />
							</div>
						</div>
					</div>
					<div class="form-column col-lg-6 col-md-12 col-sm-12">
						<div class="inner-column">
							<h4>You Don't Hesitate To Contact <br/> With Us, Now Say Hello......</h4>
						
							<div class="appointment-form">
								<form method="post" action="appointment.html">
									<div class="row clearfix">
										<div class="col-lg-6 col-md-6 col-sm-12 form-group">
											<input type="text" name="username" placeholder="Name" required=""/>
											<span class="icon fa fa-user"></span>
										</div>
										
										<div class="col-lg-6 col-md-6 col-sm-12 form-group">
											<input type="email" name="email" placeholder="Email" required=""/>
											<span class="icon fa fa-envelope"></span>
										</div>

										<div class="col-lg-6 col-md-6 col-sm-12 form-group">
											<input type="tel" name="phone" placeholder="Phone No" required=""/>
											<span class="icon fa fa-phone"></span>
										</div>

										<div class="col-lg-6 col-md-6 col-sm-12 form-group">
											<input type="text" name="department" placeholder="Department" required=""/>
											<span class="icon fa fa-home"></span>
										</div>

										<div class="col-lg-12 col-md-12 col-sm-12 form-group">
											<textarea name="message" placeholder="Message"></textarea>
										</div>

										<div class="col-lg-12 col-md-12 col-sm-12 form-group">
											<button class="theme-btn btn-style-three" type="submit" name="submit-form"><span class="txt">Send Massage</span></button>
										</div>
									</div>
								</form>
							</div>
							
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</section> 
	<section class="info-section" style={{ backgroundImage: `url(${background_6})` }}>
		<div class="auto-container">
			<div class="row clearfix">
				
				
				<div class="logo-column col-lg-3 col-md-6 col-sm-12">
					<div class="inner-column">
						<div class="logo">
							<a href="index.html"><img src={logo_2} alt="" /></a>
						</div>
					</div>
				</div>
				
			
				<div class="info-column col-lg-3 col-md-6 col-sm-12">
					<div class="inner-column">
						<div class="icon-box"><span class="flaticon-pin"></span></div>
						<ul>
							<li><strong>Address</strong></li>
							<li>125, Suitland Street, USA</li>
						</ul>
					</div>
				</div>
				
			
				<div class="info-column col-lg-3 col-md-6 col-sm-12">
					<div class="inner-column">
						<div class="icon-box"><span class="flaticon-phone-call"></span></div>
						<ul>
							<li><strong>Phone</strong></li>
							<li>+ 786 875 864 75</li>
						</ul>
					</div>
				</div>
				
				
				<div class="info-column col-lg-3 col-md-6 col-sm-12">
					<div class="inner-column">
						<div class="icon-box"><span class="flaticon-email-1"></span></div>
						<ul>
							<li><strong>E-Mail</strong></li>
							<li>support@globex.com</li>
						</ul>
					</div>
				</div>
				
			</div>
		</div>
	</section>    
      </div>
    );
};
export default Service2;
