import React from 'react';

import background1 from '../assets/images/background/1.jpg';
import pattern5 from '../assets/images/background/pattern-5.png';
import pattern6 from '../assets/images/background/pattern-6.png'; 

const Technology = ({ }) => {
    return (
<>
<section className="technology-section" style={{ backgroundImage: `url(${background1})` }}>
      <div className="pattern-layer-one" style={{ backgroundImage: `url(${pattern5})` }}></div>
      <div className="pattern-layer-two" style={{ backgroundImage: `url(${pattern6})` }}></div>
		<div class="auto-container">
			<div class="row clearfix">
				{/* <!-- Title Column --> */}
				<div class="title-column col-lg-5 col-md-12 col-sm-12">
					<div class="inner-column">
						{/* <!-- Sec Title --> */}
						<div class="sec-title light">
							<div class="title">TECHNOLOGY INDEX</div>
							<h2>We Deliver Solutions with the Goal of Trusting Workships</h2>
						</div>
					</div>
				</div>
				{/* <!-- Blocks Column --> */}
				<div class="blocks-column col-lg-7 col-md-12 col-sm-12">
					<div class="inner-column">
						<div class="row clearfix">
							{/* <!-- Technology Block --> */}
							<div class="technology-block col-lg-4 col-md-6 col-sm-12">
								<div class="inner-box">
									<a href="services-detail.html" class="overlay-link"></a>
									<div class="icon-box">
										<span class="flaticon-coding-2"></span>
									</div>
									<h6>WEB</h6>
								</div>
							</div>
							
							{/* <!-- Technology Block --> */}
							<div class="technology-block col-lg-4 col-md-6 col-sm-12">
								<div class="inner-box">
									<a href="services-detail.html" class="overlay-link"></a>
									<div class="icon-box">
										<span class="flaticon-android"></span>
									</div>
									<h6>ANDROID</h6>
								</div>
							</div>
							
							{/* <!-- Technology Block --> */}
							<div class="technology-block col-lg-4 col-md-6 col-sm-12">
								<div class="inner-box">
									<a href="services-detail.html" class="overlay-link"></a>
									<div class="icon-box">
										<span class="flaticon-apple"></span>
									</div>
									<h6>IOS</h6>
								</div>
							</div>
							
							{/* <!-- Technology Block --> */}
							<div class="technology-block col-lg-4 col-md-6 col-sm-12">
								<div class="inner-box">
									<a href="services-detail.html" class="overlay-link"></a>
									<div class="icon-box">
										<span class="flaticon-iot"></span>
									</div>
									<h6>IOT</h6>
								</div>
							</div>
							
							{/* <!-- Technology Block --> */}
							<div class="technology-block col-lg-4 col-md-6 col-sm-12">
								<div class="inner-box">
									<a href="services-detail.html" class="overlay-link"></a>
									<div class="icon-box">
										<span class="flaticon-smartband"></span>
									</div>
									<h6>WEARALABLES</h6>
								</div>
							</div>
							
							{/* <!-- Technology Block --> */}
							<div class="technology-block col-lg-4 col-md-6 col-sm-12">
								<div class="inner-box">
									<a href="services-detail.html" class="overlay-link"></a>
									<div class="icon-box">
										<span class="flaticon-tv"></span>
									</div>
									<h6>TV</h6>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	{/* <!-- End Technology Section --> */}
</>
        );
    };
    
    export default Technology;