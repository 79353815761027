import React from 'react';
import pattern3 from '../assets/images/background/pattern-3.png'; 


const PreBusiness = ({ }) => {
    return (
      <div>

{/* <!-- Call To Action Section --> */}
	{/* <!-- Preparing For Your Business --> */}
	<section className="call-to-action-section" style={{ backgroundImage: `url(${pattern3})` }}>
		<div class="auto-container">
			<div class="row clearfix">
				{/* <!-- Heading Column --> */}
				<div class="heading-column col-lg-8 col-md-12 col-sm-12">
					<div class="inner-column">
						<h2>Preparing For Your Business <br/> Success With IT Solution</h2>
					</div>
				</div>
				{/* <!-- Button Column --> */}
				<div class="button-column col-lg-4 col-md-12 col-sm-12">
					<div class="inner-column">
						<a href="contact.html" class="theme-btn btn-style-two"><span class="txt">Meet With Us</span></a>
					</div>
				</div>
			</div>
		</div>
	</section>
	{/* <!-- End Call To Action Section --> */}
</div>
  );
};

export default PreBusiness;
