import React from 'react';
import client1 from '../assets/images/clients/1.png';
import client2 from '../assets/images/clients/2.png';
import client3 from '../assets/images/clients/3.png';
import client4 from '../assets/images/clients/4.png';


const Sponsors = () => {
 

    return (
        <section className="sponsors-section">
            <div className="auto-container">
                <div className="carousel-outer">
                    <div className="sponsors-carousel owl-carousel owl-theme">
                        <div className="image-box"><a href="#"><img src={client1} alt="Client 1" /></a></div>
                        <div className="image-box"><a href="#"><img src={client2} alt="Client 2" /></a></div>
                        <div className="image-box"><a href="#"><img src={client3} alt="Client 3" /></a></div>
                        <div className="image-box"><a href="#"><img src={client4} alt="Client 4" /></a></div>
                        <div className="image-box"><a href="#"><img src={client1} alt="Client 1" /></a></div>
                        <div className="image-box"><a href="#"><img src={client2} alt="Client 2" /></a></div>
                        <div className="image-box"><a href="#"><img src={client3} alt="Client 3" /></a></div>
                        <div className="image-box"><a href="#"><img src={client4} alt="Client 4" /></a></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Sponsors;

